import {createComponent} from '@/composables/helpers';
import LoadingComponent from './LoadingOverlay.vue';

export function useLoading(globalProps = {}, globalSlots = {}) {

    return {
        show(props = globalProps, slots = globalSlots) {
            const forceProps = {
                programmatic: true,
                lockScroll: true,
                isFullPage: false,
                active: true,
            }

            const propsData = {...globalProps, ...props, ...forceProps};
            let container = propsData.container;

            if (!propsData.container) {
                container = document.body;
                propsData.isFullPage = true;
            }

            const mergedSlots = {...globalSlots, ...slots};
            const instance = createComponent('vld-container', LoadingComponent, propsData, container, mergedSlots);

            return {
                hide: instance.ctx.hide
            }
        },
    }
}
