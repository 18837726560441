<template>
    <div class="loading-anm-container" :class="'loading-anm-' + selectedAnimation">
        <span data-text="A">A</span>
        <span data-text="C">C</span>
        <span data-text="-">-</span>
        <span data-text="F">F</span>
        <span data-text="O">O</span>
        <span data-text="T">T</span>
        <span data-text="O">O</span>
    </div>
</template>
<script>
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'text',
    props: {
        color: {
            type: String,
            default: '#e30613'
        },
        height: {
            type: Number,
            default: 40
        },
        width: {
            type: Number,
            default: 40
        },
        animation: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            selectedAnimation: Math.floor(Math.random() * 4) + 1
        }
    },
    mounted() {

    },
})
</script>
<style scoped lang="scss">
.loading-anm-container {
    font-size: 84px;
    font-family: 'ac-foto', sans-serif;
    font-weight: 100;
    text-align: center;
    span {
        display: inline-block;
    }
}
.loading-anm-1 {
    span {
        position: relative;
        color: rgba(#660613, .3);
        animation: loading-anm-1-parent 5s infinite;
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #e30613;
            opacity: 0;
            animation: loading-anm-1-child 5s infinite;
        }
        @for $i from 1 through 7 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.2}s;
            }
        }
    }
}
@keyframes loading-anm-1-parent {
    0%, 35%, 100% {
        color: rgba(#660613, .3);
    }
    60%, 80% {
        color: #e30613;
    }
}
@keyframes loading-anm-1-child {
    0% {
        opacity: 1;
    }
    25%, 100% {
        opacity: 0;
    }
}


.loading-anm-2 {
    span {
        position: relative;
        color: rgba(#660613, .3);
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #e30613;
            opacity: 0;
            transform: scale(1.5);
            animation: loading-anm-2 3s infinite;
        }
        @for $i from 1 through 7 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}
@keyframes loading-anm-2 {
    0%, 75%, 100% {
        transform: scale(1.5);
        opacity: 0;
    }
    25%, 50% {
        transform: scale(1);
        opacity: 1;
    }
}



.loading-anm-3 {
    span {
        position: relative;
        color: rgba(#660613, .3);
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: attr(data-text);
            color: #e30613;
            opacity: 0;
            transform: rotateY(-90deg);
            animation: loading-anm-3 4s infinite;
        }
        @for $i from 1 through 7 {
            &:nth-child(#{$i+1})::after {
                animation-delay: #{$i*.2}s;
            }
        }
    }
}
@keyframes loading-anm-3 {
    0%, 75%, 100% {
        transform: rotateY(-90deg);
        opacity: 0;
    }
    25%, 50% {
        transform: rotateY(0);
        opacity: 1;
    }
}


.loading-anm-4 {
    span {
        color: #e30613;
        animation: loading-anm-4 1.4s infinite alternate;
        @for $i from 0 through 7 {
            &:nth-child(#{$i+1}) {
                animation-delay: #{$i*.1}s;
            }
        }
    }
}
@keyframes loading-anm-4 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
