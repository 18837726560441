import Spinner from './spinner.vue'
import Dots from './dots.vue'
import Bars from './bars.vue'
import Text from './text.vue'

export default {
    Spinner,
    Dots,
    Bars,
    Text
}
