<script>
import {defineComponent, render} from 'vue';
import {MayBeHTMLElement, removeElement} from '@/composables/helpers'
import trapFocusMixin from './trapFocusMixin.js';
import Loaders from './loaders/index';

export default defineComponent({
    name: 'VueLoading',
    mixins: [trapFocusMixin],
    props: {
        active: {
            type: Boolean,
            default: false
        },
        programmatic: Boolean,
        container: [Object, Function, MayBeHTMLElement],
        isFullPage: {
            type: Boolean,
            default: true
        },
        enforceFocus: {
            type: Boolean,
            default: true
        },
        lockScroll: {
            type: Boolean,
            default: true
        },
        transition: {
            type: String,
            default: 'fade'
        },
        // Allow user to hide the loader
        canCancel: {
            type: Boolean,
            default: false
        },
        // Do something on cancel
        onCancel: {
            type: Function,
            default: () => {}
        },
        color: {
            type: String,
            default: '#e30613'
        },
        backgroundColor: {
            type: String,
            default: '#0a0a0a'
        },
        opacity: {
            type: Number,
            default: 0.8
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 125
        },
        zIndex: {
            type: Number,
            default: 9999
        },
        loader: {
            type: String,
            default: 'text'
        },
        afterText: {
            type: String,
            default: null
        }
    },
    components: Loaders,
    emits: ['hide', 'update:active'],
    data() {
        return {
            // Don't mutate the prop
            isActive: this.active
        }
    },
    mounted() {
        document.addEventListener('keyup', this.keyPress)
    },
    methods: {
        /**
         * Proxy to hide() method.
         * Gets called by ESC button or when click outside
         */
        cancel() {
            if (!this.canCancel || !this.isActive) return;
            this.hide();
            this.onCancel.apply(null, arguments);
        },
        /**
         * Hide and destroy component if it's programmatic.
         */
        hide(destroyAll = false) {
            this.$emit('hide');
            this.$emit('update:active', false);

            if (this.programmatic) {
                this.isActive = false;

                // Timeout for the animation complete before destroying
                setTimeout(() => {
                    const parent = this.$el.parentElement;

                    if (!parent && destroyAll) {
                        let elem = document.querySelectorAll('.vld-container')
                        elem.forEach((el) => {
                            removeElement(el)
                        })
                    } else {
                        render(null, parent);
                        removeElement(parent)
                    }
                }, 150)
            }
        },
        disableScroll() {
            if (this.isFullPage && this.lockScroll) {
                document.body.classList.add('vl-shown');
            }
        },
        enableScroll() {
            if (this.isFullPage && this.lockScroll) {
                document.body.classList.remove('vl-shown');
            }
        },
        /**
         * Key press event to hide on ESC.
         */
        keyPress(event) {
            // todo keyCode is deprecated
            if (event.keyCode === 27) this.cancel()
        },
    },
    watch: {
        active(value) {
            this.isActive = value
        },
        isActive: {
            handler (value)  {
                if (value) {
                    this.disableScroll();
                } else {
                    this.enableScroll()
                }
            },
            immediate: true
        }
    },
    computed: {
        bgStyle() {
            return {
                background: this.backgroundColor,
                opacity: this.opacity,
            }
        }
    },
    beforeUnmount() {
        document.removeEventListener('keyup', this.keyPress);
    },
})
</script>

<template>
    <transition :name="transition">
        <div tabindex="0"
             class="vl-overlay vl-active"
             :class="{ 'vl-full-page': isFullPage }"
             v-show="isActive"
             :aria-busy="isActive"
             aria-label="Loading"
             :style="{ zIndex }"
        >
            <div class="vl-background"
                 @click.prevent="cancel"
                 :style="bgStyle"
            />
            <div :style="{ zIndex }">
                <div class="vl-icon">
                    <slot name="before"/>
                    <slot name="default">
                        <component
                            :is="loader"
                            :color="color"
                            :width="width"
                            :height="height"
                        />
                    </slot>
                </div>
                <div v-if="afterText" class="vl-after-text fw-bold fs-5 text-center">
                    {{ afterText }}
                </div>
            </div>

            <slot name="after"/>
        </div>
    </transition>
</template>
<style scoped>
.vl-shown {
    overflow: hidden;
}

.vl-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    display: none;
    justify-content: center;
    overflow: hidden;
    z-index: 9999;
}

.vl-overlay.vl-active {
    display: flex;
}

.vl-overlay.vl-full-page {
    z-index: 9999;
    position: fixed;
}

.vl-overlay .vl-background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
}

.vl-overlay .vl-icon, .vl-parent {
    position: relative;
}

.vl-icon {
    text-align: center;
}

.vl-after-text {

}
</style>
